import React, { useState ,useEffect} from 'react';
import './navbar.css';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import miragelogo from '../../assets/Core/Mirage Logo - Transparent.png';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth'
import useLogout from '../../hooks/useLogout';
import {ROLES} from '../../App'
import { Menu } from '../Menu/Menu';

import sample from "../../assets/Team images/sampleimg.png"










const Navbar = () => {
  const {auth} = useAuth();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [hasAdminRole,setHasAdminroles] = useState(false);
  // console.log(auth)

  useEffect(() => {
    if (auth.roles !== undefined) {
      
      const authRoles = JSON.stringify(auth.roles);
    
      if (authRoles.includes("Admin")) {
        setHasAdminroles(true);
        console.log("has admin role");
      } else {
        setHasAdminroles(false);
        console.log("does not have admin role");
      }
    } else {
      
      setHasAdminroles(false);
    }
  }, [auth.roles, auth.Roles]);
  

  


  const logout = useLogout();

  const handleLogout = async (e) => {
    await logout();
  }
    
  

    
  

  return (

  
  
  <div className='navbar'>
    <div className='navbar-core'>
      <div className="navbar-links">
        <div className="navbar-links_logo">
          <a href="home">
            <div className='navbar-halo-logo-container'>
              <img className="navbar-logo-image" alt='logo' src={miragelogo} />
            </div>
          </a>
          <a href='home'><p className='navbar-halo-text'>Mirage</p></a>
        </div>
        <div className="navbar-links_container">
          <Menu className='navbar-menu-gap'/>

        </div>
      </div>
      <div className='navbar-actions'>
        {/* <div className={`navbar-sign ${auth.accessToken? 'hidden':''}`}>
          <Link to="/signin"><p className='navbar-signin-text' >Sign in</p></Link>
          <Link to="/signup"><button className='navbar-signup-text' type='button'>Sign Up</button></Link>
        </div> */}
        {auth.accessToken &&(
          <div className={`navbar-sign ${auth.accessToken? '':'hidden'}`}>
            <Link to={'/home'}><button className='navbar-signup-text' type='button' onClick={handleLogout}>Sign Out</button></Link>
            <Link to={hasAdminRole ? '/dashboard' : '/profile'}>
              <div className="navbar-pfp-container">
                <img className='navbar-user-profile-logo' alt='profilelogo'src={auth.pfpUrl || sample}/>
              </div>
            </Link>
          </div>
          )
        }
        <div className='navbar-menu'>
          {toggleMenu
            ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
            : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
          }

          {toggleMenu && (
              <div className='navbar-menu_container scale-up-center'>
                <div className='navbar-menu_container-links'>
                  <Menu className='navbar-menubar-gap'/>
                  
                  {/* {!auth.accessToken && (
                    <div className='navbar-menu_container-link-sign'>
                      <Link to="/signin"><p className='navbar-signin-text' >Sign in</p></Link>
                      <Link to="/signup"><button className='navbar-signup-text' type='button'>Sign Up</button></Link>
                    </div>
                  )} */}

                  {auth.accessToken && (
                    <div className='navbar-menu_container-link-sign'>
                      <Link to={'/home'}><button className='navbar-signup-text' type='button' onClick={handleLogout}>Sign Out</button></Link>
                      <Link to={hasAdminRole ? '/dashboard' : '/profile'}>
                        <div className="navbar-pfp-container">
                          <img className='navbar-user-profile-logo' alt='profilelogo'src={auth.pfpUrl || sample}/>
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
          )}
        </div>
      </div>
    </div>
  </div>
)

    
        
}

export default Navbar;
