import React from 'react'
import './footer.css';
import { Menu } from '../Menu/Menu';
import { faInstagram,faYoutube,faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';




  
const Footer = () => {
  return (
    
      <div className="footer-main">
          <div className='footer-header-container'>
            <h1 className='footer-head-large'>LET'S PLAY TOGETHER</h1>
            <p className='footer-para'>Take action today and join us for an event experience like no other.</p>
            <hr className='footer-hr' />
          </div>

          <div className="footer-main-container">
            <div className='footer-navbar'>
              <Menu className='footer-menu-gap'/>
            </div>
            <div className="footericons">
              <a href='https://www.instagram.com/mirage.cgcj/'><FontAwesomeIcon className= "logos footerlogo" icon={faInstagram} /> </a>
              <a href='https://www.youtube.com/channel/UCsBaH0ygQGMgOu5dExAw02A'><FontAwesomeIcon className= "logos footerlogo" icon={faYoutube} /> </a>
              <a href='https://twitter.com/Mirage_CGCJ'><FontAwesomeIcon className= "logos footerlogo" icon={faTwitter} /> </a>
              <a href='mailto:mirage.cgcj@gmail.com'><FontAwesomeIcon className= "logos footerlogo" icon={faEnvelope} /> </a>
            </div>
            <div className="footer-map-parent">
              <iframe title ='gps' className='footer-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3431.334298942719!2d76.60280207537087!3d30.680868974609336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fe5b795735cfd%3A0xb287b4430b6720fb!2sChandigarh%20Group%20of%20Colleges%20Jhanjeri%20Mohali!5e0!3m2!1sen!2sin!4v1708709505913!5m2!1sen!2sin"   allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>

          <div className="footer-copyright">
            
            <div className='footer-bottom-text'>
              <a href='/privacy'><h3 className='footer-copyright-para'>All Rights Reserved By Mirage 2024</h3></a>
              <Link to="/devs"><h3 className='footer-copyright-para'>Developed By Inditech</h3></Link>
              
            </div>
            <div className='footer-signin-logo-container'>
              <Link to="/signin"><FontAwesomeIcon className= "logos footersignin" icon={faEnvelope} /></Link>
            </div>
            
          </div>
      </div>
    
    
  )
}

export default Footer
