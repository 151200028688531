

import img1 from '../../assets/Sponsors/gfgsc.png';
import img2 from '../../assets/Sponsors/WhatsApp Image 2024-03-19 at 3.10.35 PM.jpeg';
import img3 from '../../assets/Sponsors/WhatsApp Image 2024-03-20 at 4.06.33 PM.jpeg';


const sponsorImages = [
  {
    imageurl: img1,
    onClickLink: '',
    alt:"GFG"
  },
  {
    imageurl: img2,
    onClickLink: '',
    alt:"GFG"
  },
  {
    imageurl: img3,
    onClickLink: '',
    alt:"GFG"
  },

];


export default sponsorImages;
