import slide_1 from '../../assets/CGC Gallery/cgc1.jpg';
import slide_2 from '../../assets/CGC Gallery/cgc2.jpg';
import slide_3 from '../../assets/CGC Gallery/cgc3.jpg';
import slide_4 from '../../assets/CGC Gallery/cgc4.jpg';
import slide_5 from '../../assets/CGC Gallery/cgc5.jpeg';
import slide_7 from '../../assets/CGC Gallery/cgc7.jpg';
import slide_8 from '../../assets/CGC Gallery/cgc8.webp';
import slide_9 from '../../assets/CGC Gallery/cgc9.jpg';


export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1024, min: 800 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const productData = [
  {
    id: 1,
    imageurl:slide_1
  },
  {
    id: 2,
    imageurl:slide_2
  },
  {
    id: 3,
    imageurl:slide_3
  },
  {
    id: 4,
    imageurl:slide_4
  },
  {
    id: 5,
    imageurl:slide_5
  },
  {
    id: 6,
    imageurl:slide_7
  },
  {
    id: 7,
    imageurl:slide_8
  },
  {
    id: 8,
    imageurl:slide_9
  },


];
