import React from 'react';
import './Privacy.css';



const Privacy = () => {
  
  return (
    <div className='privacy-main'>
      

      <div className='privacy-core'>
        <div className='privacy-box'>
          <div className='privacy-text-box'>
            <h1>Privacy Policy for Mirage-2024</h1>
            <br/>
            <p>At Mirage-2024, we are committed to protecting the privacy and security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services.</p>
            <br/>
            <h2>Information We Collect</h2>
            <br/>
            <p>We may collect personal information from you when you register for an event on our website. This information may include:</p>
            <br/>
            <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Mailing address</li>
                <li>Payment information (such as credit card details)</li>
            </ul>
            <br/>
            <p>We may also collect non-personal information automatically when you visit our website, such as your IP address, browser type, operating system, referring website, and browsing behavior.</p>
            <br/>
            <h2>How We Use Your Information</h2>
            <br/>
            <p>We use the information we collect to:</p>
            <br/>
            <ul>
                <li>Process your event registration and communicate with you about the event</li>
                <li>Provide customer support and respond to your inquiries</li>
                <li>Improve our website and services</li>
                <li>Send you marketing communications, if you have opted in to receive them</li>
                <li>Comply with legal obligations</li>
            </ul>
            <br/>
            <h2>Data Security</h2>
            <br/>
            <p>We take the security of your personal information seriously and take reasonable measures to protect it from unauthorized access, disclosure, alteration, and destruction. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your information.</p>
            <br/>
            <h2>Disclosure of Your Information</h2>
            <br/>
            <p>We may disclose your personal information to third parties in the following circumstances:</p>
            <br/>
            <ul>
                <li>With your consent</li>
                <li>To our service providers who assist us in operating our website and providing our services</li>
                <li>To comply with legal requirements or protect our rights</li>
            </ul>
            <br/>
            <p>We do not sell, rent, or trade your personal information to third parties for marketing purposes.</p>
            <br/>
            <h2>Third-Party Links</h2>
            <br/>
            <p>Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third parties. We encourage you to read the privacy policies of any third-party websites you visit.</p>
            <br/>
            <h2>Children's Privacy</h2>
            <br/>
            <p>Our website and services are not directed at children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you believe that we have collected personal information from a child under the age of 13, please contact us immediately.</p>
            <br/>
            <h2>Changes to This Privacy Policy</h2>
            <br/>
            <p>We reserve the right to update or change our Privacy Policy at any time. Any changes will be posted on this page with an updated revision date. We encourage you to review this Privacy Policy periodically for any updates.</p>
            <br/>
            <h2>Contact Us</h2>
            <br/>
            <p>If you have any questions or concerns about our Privacy Policy or our practices regarding your personal information, please contact us at mirage.cgcj@gmail.com.</p>
            <br/>
            <p><em>This Privacy Policy was last updated on 9th March 2024.</em></p>
            <br/>
          </div>
        </div>
        
        
        

      </div>

    </div>
  )
}

export default Privacy;





