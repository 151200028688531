// scheduleData.js

const scheduleData = [
    {
      date: "5th March",
      events: [
        {
          title: "Registrations Start",
          icon: "fa fa-asterisk text-success",
          details: {
            desc: "Hurry Up! Registrations Started",
            addInfo: "",
          },
          footer: "~Team Mirage"
        },
      ]
    },
    {
      date: "15th March",
      events: [
        {
          title: "Registrations End",
          icon: "fa fa-asterisk text-success",
          details: {
            desc: "Registrations Ended",
            addInfo: "",
          },
          footer: "~Team Mirage"
        },
      ]
    },
    {
      date: "20th March",
      events: [
        {
          title: "Event Day!",
          icon: "fa fa-asterisk text-success",
          details: {
            desc: "Yayy!",
            addInfo: "",
          },
          footer: "~Team Mirage"
        },
      ]
    },
    {
      date: "22nd March",
      events: [
        {
          title: "Award Ceremony!",
          icon: "fa fa-asterisk text-success",
          details: {
            desc: "Yayy!",
            addInfo: "",
          },
          footer: "~Team Mirage"
        },
      ]
    },
    
    
    
  ];
  
  export default scheduleData;
  