import React from 'react';
import './College.css';
import Carousel from "react-multi-carousel";
import { productData, responsive } from "./College-Data";
import cgc from "../../assets/CGC Gallery/cgc-jhanjeri-logo-white.png"
import naac from "../../assets/CGC Gallery/cgc-jhanjeri-naac-white.png"
import cec from "../../assets/CGC Gallery/cec.png"



const About = () => {
  
  const images = productData.map((item) => (
    <div className="college-card">
      <img className="college-product-image" src={item.imageurl} alt="" />
    </div>
  ));
  
  return (
    <div className='college-main'>
      <div className='college-header-container'>
      <a href="https://www.cgc.ac.in/" target='_blank'> <img className="college-cgc-logo" src={cgc}/> </a>
        <div className='college-header-center-container' >
         <a href="https://www.cgc.ac.in/" target='_blank'> <img className="college-cec-logo" src={cec}/></a>
        </div>
        <img className="college-naac-logo" src={naac}/>
      </div>

      <div className='college-core'>
        <div className='college-box'>
          <div className="college-college-slideshow">
            <div className="college-Carousel">
              <Carousel showDots={false} responsive={responsive} autoPlay={true} autoPlaySpeed={5000} infinite={true}>
                {images}
              </Carousel>
            </div>
          </div>
          <div className='college-text-box'>
            <p className='college-college-text'>
            Step into the vibrant world of Chandigarh group of Colleges, Jhanjeri. It is recognised 
            internationally as a leading college/university in the areas of technical, natural sciences, 
            management and law, also renowned for business-oriented approach. Here at CGC, we focus on 
            sustainability and an open minded and innovative study environment. We provide students with a 
            platform to pursue their interests and passions. We offer a space to students to come together, so 
            that they can engage more in co-curricular activities with keeping a good impact in academics 
            too.
            Embrace the challenges, seize the opportunities,make the most of every moment at CGC!!!
            </p>
          </div>
        </div>
        
        
        

      </div>

    </div>
  )
}

export default About





