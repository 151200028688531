import {React} from 'react';
import './Home.css';
import ellipse from '../../assets/Misc/ellipse-12.svg';
import miragelogo from '../../assets/Core/Mirage Logo - Transparent.png';
import { useNavigate } from 'react-router-dom';
import { faInstagram,faYoutube,faTwitter} from '@fortawesome/free-brands-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope} from '@fortawesome/free-solid-svg-icons';
import Countdown from 'react-countdown';




const targetDate = new Date('2024-04-05T00:00:00').getTime();
const targetDate2 = new Date('2024-03-22T12:00:00').getTime();

// Renderer function



const Home = () => {
  const navigate = useNavigate();

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      
      return null;
    } else {
      // Render countdown elements separately
      return (
        <>
        
        <div className="home-countdown">
          <h1 className='registernow-text-design'>Enter The Illusions In</h1>
          <div className='home-countdown-container'>
          {days !== 0 && (
            <>
              <div className='home-main-count-heading'>
              
                <div className='home-count-heading'>{days.toString().padStart(2, '0')}</div> Days
              </div>
              :
            </>
          )}
            <div className='home-main-count-heading'>
              <div className='home-count-heading'>{hours.toString().padStart(2, '0')}</div>Hours
            </div>
            :
            <div className='home-main-count-heading'>
              <div className='home-count-heading'>{minutes.toString().padStart(2, '0')}</div> Minutes
            </div>
            :
            <div className='home-main-count-heading'>
              <div className='home-count-heading'>{seconds.toString().padStart(2, '0')}</div> Seconds
            </div>
          </div>
        </div>
        </>
      );
    }
  };
  const renderer2 = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return null
    //   return <div className="home-button-container">
    //   <button className="home-cssbuttons-io-button" onClick={() => { navigate("/registration"); }}>REGISTER NOW!
    //     <div className="home-icon">
    //       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path></svg>
    //     </div>
    //   </button>
    // </div>;
    } else {
      // Render countdown elements separately
      return <div className="home-countdown">
        <h1 className='registernow-text-design'>Registrations Start On 22nd March</h1>
        </div>
    }
  };

  
  

  return (
    
    <div className='home-main'>
      <div className="home-core">
          
        <div className='home-hero'>
          <div className='home-hero-logo'>
            
            <img className="home-ellipse-img" alt="" src={ellipse} />
            <img className="home-halolegion-logo " alt="" src={miragelogo} />

            <div className="home-hero-text">
              <h1 className="home-hero-halocon-text">MIR  AGE</h1>
            </div>
          </div>
          <p className="home-info-text">
                Illusions Collide, Victory Inside
            </p>
        </div>
        
        <div className='home-bottom'>
            
            
            <Countdown date={targetDate} renderer={renderer} />
            <div className="home-copyright">
                <div className="homeicons">
                  <a href='https://www.instagram.com/mirage.cgcj/'><FontAwesomeIcon className= "home-logos homelogo" icon={faInstagram} /> </a>
                  <a href='https://www.youtube.com/channel/UCsBaH0ygQGMgOu5dExAw02A'><FontAwesomeIcon className= "home-logos homelogo" icon={faYoutube} /> </a>
                  <a href='https://twitter.com/Mirage_CGCJ'><FontAwesomeIcon className= "home-logos homelogo" icon={faTwitter} /> </a>
                  <a href='mailto:mirage@cgc.acm.org'><FontAwesomeIcon className= "home-logos homelogo" icon={faEnvelope} /> </a>
                </div>
          </div>
            <Countdown date={targetDate2} renderer={renderer2} />
            
          <div className="home-button-container">
            <button className="home-cssbuttons-io-button" onClick={() => { navigate("/college"); }}>Our College
              <div className="home-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path>
                </svg>
              </div>
            </button>
            <button className="home-cssbuttons-io-button " onClick={() => { navigate("/members"); }}>Our Team
              <div className="home-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path></svg>
              </div>
            </button>
          </div>
          
          
          
        </div>
      </div>
    </div>
  );
}

export default Home;
