import {useState,useEffect} from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate,useLocation } from "react-router-dom";
// import { faHtml5, faCss3Alt, faJs,faReact,faPython,faAws,faNodeJs, faLinkedin, faGithub} from '@fortawesome/free-brands-svg-icons'; 
import { faChartSimple, faHouse, faPeopleGroup, faSatellite } from "@fortawesome/free-solid-svg-icons";



const USERS_URL = '/users';






export const nav_card_Data = [
    {
        name: "Home",
        icon: faHouse,
        btn: 'home',
        roles:["Admin"]
    },
    {
        name: "Analytics",
        icon: faChartSimple,
        btn: 'analytics',
        roles:["Admin"]
    },
    {
        name: "Social Media",
        icon: faSatellite,
        btn: 'social',
        roles:["Admin"]
    },
    {
        name: "Users",
        icon: faPeopleGroup,
        btn: 'users',
        roles:["Admin"]
    },
    {
        name: "Players",
        icon: faPeopleGroup,
        btn: 'players',
        roles:["Owner"]
    },
    
    
];


export const hero_card_Data = [
    {
        name: "Home 2",
        icon: faHouse,
        
    },
    {
        name: "Analytics 2",
        icon: faChartSimple,
    },
    {
        name: "Social Media 2",
        icon: faSatellite,
    },
    
];

