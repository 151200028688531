import React from 'react';
import './ToyCathon.css';
import toycathonlogo from '../../assets/Core/Toykathon transparent.png';
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from 'react-router-dom';
import Countdown from 'react-countdown';
import { Link } from 'react-router-dom';


const targetDate = new Date('2024-03-22T12:00:00').getTime();

const Home = () => {
  const navigate = useNavigate();
  
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <div className="home-button-container">
      <button className="home-cssbuttons-io-button" onClick={() => { navigate("/registration"); }}>REGISTER NOW!
        <div className="home-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path></svg>
        </div>
      </button>
    </div>;
    } else {
      // Render countdown elements separately
      return (
        <div className="ace-countdown">
          {days !== 0 && (
            <>
              <div className='ace-count-heading'>{days.toString().padStart(2, '0')}</div>


            :
            </>
          )}
            <>
              <div className='ace-count-heading'>{hours.toString().padStart(2, '0')}</div>


            :
            </>
            <>
              <div className='ace-count-heading'>{minutes.toString().padStart(2, '0')}</div>


            :
            </>
            <>
              <div className='ace-count-heading'>{seconds.toString().padStart(2, '0')}</div>


            
            </>
          
            

        </div>
      );
    }
  };

  


  return (
    <div className='hotdrop-main'>
      <div className='hotdrop-cards-row'>
        <div className='hotdrop-horizontal-card'>
          <div className='hotdrop-horizontal-card-left'>
            <img className="hotdrop-horizontal-card-userimage" alt="" src={toycathonlogo}/>
          </div>
          <div className='hotdrop-horizontal-card-right'>
            {/* <p>Click here to Register</p> */}
            <p className='register_open_date_time'>An 24 Hour Hackathon</p>
            <Countdown date={targetDate} renderer={renderer} />

            
          </div>
        </div>
      </div>
      <div className="hotdrop-bottom">
        <div className='hotdrop-info'>
          <p>Toycathons are dynamic platforms  fostering creativity, innovation, and community engagement in toy and game design, aligning with India's vision of 'Vikasit Bharat' and 'Vikashseel Bharat'. Participants work individually or in teams to develop toy prototypes based on themes like sustainable toys, inclusive play, and STEM education. These events offer educational opportunities, addressing social issues and serving as incubators for entrepreneurial ventures. Toycathons provide a collaborative environment for diverse backgrounds to explore new ideas, experiment with materials and technologies, and create innovative toys. Ultimately, they cultivate a vibrant community dedicated to enhancing the world of play and enriching children's lives in India and beyond.</p>
        </div>
        


      </div>

    </div >


  )
}

export default Home












