// membersData.js
import karan from  '../../assets/Team images/Karan.jpg'
import rajsingh from  '../../assets/Team images/Raj-SIngh.jpg'
import priya from  '../../assets/Team images/Priya-Mann.jpg'
import vanshika from  '../../assets/Team images/Vanshika.jpg'
import harveen from '../../assets/Team images/Harveen-Kaur.jpg'
import pragyansh from "../../assets/Team images/paragyansh.jpg"
import vibhuti from "../../assets/Team images/vibhuti.jpg"
import shubham from "../../assets/Team images/shubham yadav.jpg"
import manmohan from "../../assets/Team images/manmohan.jpg"
import prikshit from "../../assets/Team images/prikshit.jpg"
import navneet from "../../assets/Team images/navneet.jpg"
import mohit from "../../assets/Team images/mohit.jpg"
import shekhar from "../../assets/Team images/shekhar.jpg"
import sumit from "../../assets/Team images/sumity yadav.jpg"
import lakshay from "../../assets/Team images/lakshay aneja.jpg"
import angad from "../../assets/Team images/angad mehra.jpg"
import vaishnavee from "../../assets/Team images/Vaishnavee.jpg"
import abhinav from "../../assets/Team images/Abhinav.jpg"
// import sample from "../../assets/Team images/sampleimg.png"


import { faHtml5, faCss3Alt, faJs,faReact,faPython,faAws,faNodeJs, faLinkedin, faGithub} from '@fortawesome/free-brands-svg-icons'; 
import { faInstagram,faFacebook,faDiscord,faTwitter,faReddit,faAndroid } from '@fortawesome/free-brands-svg-icons'; 
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export const skillsData = [
    { name: "HTML", icon: faHtml5 },
    { name: "CSS", icon: faCss3Alt },
    { name: "JavaScript", icon: faJs },
    { name: "React", icon: faReact },
    { name: "Python", icon: faPython },
    { name: "Aws", icon: faAws },
    { name: "NodeJs", icon: faNodeJs },
    { name:"Android", icon:faAndroid },
    
    
];
export const socialMediaData = {
    "facebook": faFacebook,
    "twitter": faTwitter,
    "instagram": faInstagram,
    "discord":faDiscord,
    "reddit": faReddit,
    "linkedin":faLinkedin,
    "github":faGithub,
    "email":faEnvelope

};



const membersData = [
    {
        name: "Karan Kumar Agrawal",
        role: "Founder",
        description: `A versatile individual with a passion for coding, gaming, and entrepreneurship. Whether I'm crafting lines of code, exploring virtual worlds, or chasing innovative ideas, I thrive on creative challenges and strategic thinking. Tech, health, and fitness are integral to my lifestyle.`,
        imageUrl: karan,
        socialMedia: {
            instagram: "https://www.instagram.com/official.kxran/",
            linkedin:"https://www.linkedin.com/in/karan-agrawal-977746232/",
            twitter:"https://twitter.com/karann__25",
            github:"https://github.com/KDrop25",
            email:"mailto:karan25.pvt@gmail.com"
        },
        skills: ["JavaScript","HTML","NodeJs","Aws","Python","React","CSS"],
        
        
    },
    
    {
        name: "Prayansh Chauhan",
        role: "Frontend Developer",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        imageUrl:pragyansh,
        socialMedia: {
            instagram: "https://www.instagram.com/xpragyansh/",
            github: "https://www.facebook.com/",
            twitter:"https://twitter.com/PragyanshC2905",
            linkedin:"https://www.linkedin.com/in/pragyansh-chauhan-488835295/",
            facebook:"https://www.facebook.com/pragyansh.chauhan",
            email:"mailto:cpragyansh@gmail.com"

        },
        skills: ["HTML", "CSS", "JavaScript" , "React"]
    },
    {
        name: "Vaishnavee Rana",
        role: "Editorial Head", 
        description: `A passionate being ,focusing on enjoying all aspects of life and learning new things.
        Having interests in speeches,anchoring and coding.On my way of learning new things that are going to help me develop into my best version.
        Drawing provides a calm breeze to my soul.`,
        imageUrl:vaishnavee,
        socialMedia: {
            instagram: "https://www.instagram.com/vaishnavee_ee",
            twitter:"https://twitter.com/vaishnavee33525",
            linkedin:"https://www.linkedin.com/in/vaishnavee-rana-760419299/",
            email:"mailto:vaishnavee.0001@gmail.com"

        },
        skills: []
    },
    {
        name: "Manmohan Kumar",
        role: "Social Media Manager",
        description: `A committed B.Tech student at CGC, Jhanjeri, pursuing a degree in Artificial Intelligence and Machine Learning. With a passion for AI, I'm dedicated to academic excellence and eager to contribute to this dynamic field. Actively involved in collaborative projects, I aim to shape the evolving landscape of innovation. Excited to explore the world and integrate diverse experiences to fuel both personal growth and creativity in my work.`,
        imageUrl:manmohan,
        socialMedia: {
            instagram: "https://www.instagram.com/RealManmohanKumar",
            github:"https://www.github.com/AgentGhost001",
            linkedin:"https://www.linkedin.com/in/manmohan-kumar-1a643a2b5/",
            email:"mailto:Kumarmanmohan159@gmail.com"

        },
        skills: []
    },
    {
        name: "Priya Maan",
        role: "PR Head",
        description: `A dedicated person, always looking for something new and motivated towards what I do .I like to interact with people and always eager to learn new things from them.  Achieving personal and professional growth and developing  new skills is what I am passionate about.`,
        imageUrl:priya,
        socialMedia: {
            instagram: "https://www.instagram.com/itzz.priya02",
            linkedin:"https://www.linkedin.com/in/priya-maan-6079912b1?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
            email:"mailto:priyamaan706@gmail.com"

        },
        skills: []
    },
    {
        name: "Navneet Singh",
        role: "Frontend Developer",
        description: `Belonging from Sirsa, haryana, pursuing Btech in the stream of artificial intelligence and machine learning at Chandigarh Group of Colleges Jhanjeri. 
        My hobbies are exploring new things and drawing.
        Spending hours on coding and improving my skills on daily basis. 	
          Prioritising  coding skills shows my  passion for coding.
        `,
        imageUrl:navneet,
        socialMedia: {
            instagram: "https://www.instagram.com/navneetx07",
            github: "https://www.github.com/Navneetsinghx07",
            twitter:"https://twitter.com/navneetx07",
            linkedin:"https://www.linkedin.com/in/navneetsingh07/",
            email:"mailto:navneetx07@gmail.com"
        },
        skills: ["HTML", "CSS"]
    },
    {
        name: "Mohit Choudhary",
        role: "Frontend Developer",
        description: `Hailing from Himachal Pradesh, playing video games and travelling makes me thrilled.
        My goal in life is to get a job at Google.
        Love for  programming languages, that's what makes me passionate about coding.
        `,
        imageUrl:mohit,
        socialMedia: {
            instagram: "https://www.instagram.com/mohit.choudharry",
            twitter:"https://twitter.com/Mohit__990",
            linkedin:"https://www.linkedin.com/in/mohit-choudhary-0026122b5?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
            email:"mailto:navneetx07@gmail.com"
        },
        skills: ["HTML", "CSS"]
    },
    {
        name: "Shekhar Kashyap",
        role: "Backend Developer",
        description: `Currently I am pursuing B.Tech. in CSE from Chandigarh Engineering College, Jhanjeri Mohali. Playing cricket and online games is what I love to do.
        A full stack Web developer with extra skills of C,C++ and OOPs ,and willing to grow efficiently in this field`,
        imageUrl:shekhar,
        socialMedia: {
            instagram: "https://www.instagram.com/Shekhar0165",
            github:"https://github.com/Shekhar0165",
            twitter:"https://x.com/Shekhar00165?t=he32UfirUfITe1Qx7WiJ1Q&s=09",
            linkedin:"https://www.linkedin.com/in/shekhar0165",
            email:"mailto:shekharkashyap913@gmail.com"

        },
        skills: ["HTML", "CSS", "JavaScript","React","NodeJs"]
    },
    {
        name: "Prikshit Sharma",
        role: "Management Team",
        description: `Currently pursuing  B.tech AIML at CGC Jhanjheri while serving as a management head at Mirage.Hailing from Himachal Pradesh,I'm a passionate music enthusiast and a keen traveller, finding joy in exploring new cultures and melodies .
        To become a music director ,is the dream I live for.`,
        imageUrl:prikshit,
        socialMedia: {
            instagram: "https://www.instagram.com/prikshit_sharma6390",
            twitter:"https://twitter.com/_PrikshitSharma",
            linkedin:"https://www.linkedin.com/in/prikshit-sharma-799183296/",
            email:"mailto:prikshitsharma.pvt@gmail.com"

        },
        skills: []
    },
    {
        name: "Rajpratap Singh Tomar",
        role: "Graphic Designer",
        description: `Hello I'm Raj pratap singh tomar. I am persuing my studies from chandigarh Engineering college.
        Gaming , editing , graphics designer
        To be a gamer
        Gaming career
        Webtoon , comic design
        Consistency is the key. A classic gamer with no enemies`,
        imageUrl:rajsingh,
        socialMedia: {
            instagram: "https://www.instagram.com/Rajover69",
            twitter:"https://twitter.com/Rajff69_",
            linkedin:"https://www.linkedin.com/in/raj-singh-7149232b6/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
            email:"mailto:singhraj200576@gmail.com"

        },
        skills: []
    },
    {
        name: "Abhinav Aggarwal",
        role: "Graphic Designer",
        description: `Hey, I am Abbinav from Sirsa, Haryana. Working with the team as a graphic designer lead. I am expert in canva.`,
        imageUrl: abhinav,
        socialMedia: {
            instagram: "https://www.instagram.com/abhinavaggarwal02/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D",
            email:"mailto:abhinavaggarwal0104@gmail.com"
        },
        skills: [],
        
        
    },
    {
        name: "Angad Mehra",
        role: "Video Editor",
        description: `A first year student learning data science and machine learning in python .
        Training ,controlling and conquering my mind and practicing for the betterment of my life.  
        Being a good human to everyone.`,
        imageUrl: angad,
        socialMedia: {
            instagram: "https://www.instagram.com/?hl=en",
            facebook: "https://www.facebook.com/",
        },
        skills: [],
        
        
    },
    {
        name: "Shubham Yadav",
        role: "Frontend Developer",
        description: `Pursuing my b.tech in ECE fron CECJ.`,
        imageUrl:shubham,
        socialMedia: {
            instagram: "https://www.instagram.com/shubham_yadav0402",
            github: "https://www.github.com/Shubham34SR",
            linkedin:"linkedin.com/in/shubham-yadav-5730b5284",
            email:"mailto:sy609360@gmail.com"
        },
        skills: ["HTML", "CSS"]
    },
    {
        name: "Harveen Kaur",
        role: "PR Team",
        description: "Always been a curious being  for the workings of the AI . Always passionate about learning new things in any field that catches my attention.  An adventurous person,a fast learner, and a good  grasper of  knowledge. About hobbies, its the Bhangra  that makes me  the happiest.",
        imageUrl:harveen,
        socialMedia: {
            instagram: "https://www.instagram.com/kaur_9129",
            linkedin:"https://www.linkedin.com/in/harveen-k-77645526b",
            email:"mailto:harveen91205@gmail.com"

        },
        skills: []
    },
    {
        name: "Lakshay Aneja",
        role: "Python Developer",
        description: "A passionate learner trying to know more about programming languages and also  honing my skills in Cricket.",
        imageUrl:lakshay,
        socialMedia: {
            instagram: "https://www.instagram.com/lakshay_aneja_",
            linkedin:"https://www.linkedin.com/in/lakshay-aneja-b7b3762b5?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
            email:"mailto:lakshayaneja2@gmail.com"

        },
        skills: ["Python"]
    },
    {
        name: "Vibhuti Kanwar",
        role: "Editorial Team",
        description: `A recent high school graduate ,dedicated and reliable person ,having a position in the literature team in ''Mirage''.
        Gained valuable experience by  writing compelling copies for a variety of clients through freelance. With strong research and editing skills to use in new setting and contribute to the success of the event.`,
        imageUrl:vibhuti,
        socialMedia: {
            instagram: "https://www.instagram.com/vibhutiikanwar",
            twitter:"https://twitter.com/vibhutiikanwar",
            linkedin:"https://www.linkedin.com/in/vibhuti-kanwar-391511274?trk=contact-info",
            email:"mailto:vibhuti.kanwar251@gmail.com"

        },
        skills: []
    },
    {
        name: "Sumit Yadav",
        role: "App Developer",
        description: `Developing apps
        I am a very fast learner and have a knack for workarounds.
        Hailing from Up,I am currently developing projects in android app development.
        I aspire to become one of the top application developer.`,
        imageUrl:sumit,
        socialMedia: {
            instagram: "https://www.instagram.com/lord_own_acc?utm_source=qr&igsh=YnBsZnk2dHQwYnpi",
            linkedin:"https://www.linkedin.com/in/sumit-yadav-83899a293",
            email:"mailto:sumity12019@gmail.com"

        },
        skills: ["Android"]
    },
    
    {
        name: "Vanshika Bansal",
        role: "Anchor & Writer",
        description: `Public speaking ,participating in debates , reading articles,  gaining knowledge about random things  are my ultimate skills.Gaining knowledge about well established business is my ‘go to’ thing.
        My ultimate goal is to provide as many employment opportunities as possible.`,
        imageUrl:vanshika,
        socialMedia: {
            instagram: "https://www.instagram.com/Vanshikabansal_09 ",
            twitter:"https://twitter.com/Vanshikab09",
            linkedin:"https://www.linkedin.com/in/vanshika-bansal-8850632a5",
            email:"mailto:v8856269@gmail.com"

        },
        skills: ["HTML", "CSS", "JavaScript"]
    },
    
    

];

export default membersData;
