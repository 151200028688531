  import React from 'react';
import './Ace.css';
import aceimg from '../../assets/Core/Ace Logo - Transparent.png'
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from 'react-router-dom';
import Countdown from 'react-countdown';
import { Link } from 'react-router-dom';

const targetDate = new Date('2024-03-22T12:00:00').getTime();

const Ace = () => {

  const navigate = useNavigate();
  
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <div className="home-button-container">
      <button className="home-cssbuttons-io-button" onClick={() => { navigate("/registration"); }}>REGISTER NOW!
        <div className="home-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path></svg>
        </div>
      </button>
    </div>;
    } else {
      // Render countdown elements separately
      return (
        <div className="ace-countdown">
          {days !== 0 && (
            <>
              <div className='ace-count-heading'>{days.toString().padStart(2, '0')}</div>


            :
            </>
          )}
            <>
              <div className='ace-count-heading'>{hours.toString().padStart(2, '0')}</div>


            :
            </>
            <>
              <div className='ace-count-heading'>{minutes.toString().padStart(2, '0')}</div>


            :
            </>
            <>
              <div className='ace-count-heading'>{seconds.toString().padStart(2, '0')}</div>


            
            </>
          
            

        </div>
      );
    }
  };

  return (
    <div className='ace-main'>
      <div className='ace-cards-row'>
        <div className='ace-horizontal-card'>
          <div className='ace-horizontal-card-left'>
            <img className="ace-horizontal-card-userimage" alt="" src={aceimg}/>
          </div>
          <div className='ace-horizontal-card-right'>
            {/* <p>Click here to Register</p> */}
            <p className='register_open_date_time'>An E-Sports Level Valorant Tournament</p>
            <Countdown date={targetDate} renderer={renderer} />

            {/* <Link to='/Registration'><button className="ace-event-button" type='button'>Register</button></Link> */}
          </div>
        </div>
      </div>
      <div className="ace-bottom">
        <div className='ace-info'>
          <p>
          With  a diverse and action-packed environment Valorant is an amusement designed with a 
          strategic gameplay which is a  captivating  ambiance for  first person shooter[FPS] enthusiasts.   
          
          Valorant is  going to be a roller coaster ride ,featuring multiple bombsites ,chokepoints, weapons and 
          agents ,each having signature abilities of their own.

          Experience the pinnacle of this game in our cutting-edge 5v5 tournament.
          The teams enter a fierce single-elimination format,where each match is a do-or-die scenario.
          The winning teams from each of the rounds advance directly to the semifinals.
          The victorious squads proceed to the finals ,adhering to standard valorant rules .
          This is going to be an intense 5v5 showdown  revealing the  ultimate champion.

          The thrill of victory and the drive to improve  will keep  the players engaged and motivated 
          to disclose their artistry in this showdown.

          Overall,the gaming environment is  going to be competitive and strategically rich , providing
          players with an engaging and rewarding experience.
          </p>
        </div>
        


      </div>

    </div >


  )
}

export default Ace












