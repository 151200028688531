import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Registration-Form.css';
import acelogo from '../../assets/Core/Ace Logo - Transparent.png';
import hotdroplogo from '../../assets/Core/HotDrop Logo - Transparent.png';
import toycathonlogo from '../../assets/Core/Toykathon transparent.png';
import booyahimg from '../../assets/Core/Booyah Log - Transparent.png'
import PlayerInfo from './PlayerInfo';
import Summary from './summary';
import axios from '../../api/axios';

const REGISTRATION_URL = "/registration"

const Registration = () => {
  const navigate = useNavigate();
  const [currentScreen, setCurrentScreen] = useState(1);

  const [playersData, setPlayersData] = useState([]);
  const [playerNumber,setPlayerNumber] = useState(null);
  const [success,setSuccess] = useState(false);
  const [sum,setSum] = useState(false);
  const [err,setErr] = useState(null)

  const [player1data,setPlayer1Data] = useState(null);
  const [player2data,setPlayer2Data] = useState(null);
  const [player3data,setPlayer3Data] = useState(null);
  const [player4data,setPlayer4Data] = useState(null);
  const [player5data,setPlayer5Data] = useState(null);
  const [player6data,setPlayer6Data] = useState(null);

  const [applicantname,setApplicantname] = useState('');
  const [applicantcontact,setApplicantcontact] = useState('');
  const [applicantemail,setApplicantemail] = useState('');
  const [applicantcollege,setApplicantCollege] = useState('');
  const [teamname,setTeamName] = useState('');
  const [teamleader,setTeamleader] = useState('');
  const [selectedEvent, setSelectedEvent] = useState('');
  const [otherCollege, setOtherCollege] = useState('');
  const [showOtherCollegeInput, setShowOtherCollegeInput] = useState(false); // State to manage visibility of the "Other" college input field


  // useEffect(()=>{
  //   updatebackenddata(playersData);
    
  // },[playersData])

  useEffect(()=>{
    console.log(player6data);
  },[player6data]);

  

  const saveData = async (formdata) => {
    let newPlayersData;
    
    if (playersData.length === 0) {
      newPlayersData = [formdata];
    } else {
      newPlayersData = [
        ...playersData.slice(0, playerNumber - 1),
        formdata,
        ...playersData.slice(playerNumber)
      ];
    }
  
    // Update the backend data and wait for it to finish
    await updatebackenddata(newPlayersData);
  
    // Update the state with the new data
    await setPlayersData(newPlayersData);
    
    // Return a resolved promise
    return Promise.resolve();
  };
  


  const handleSubmit = async () => {
    console.log("handlesubmit Running~~~!!!!!!!!")
    console.log(data)
    try {
        const response = await axios.post(REGISTRATION_URL,
            JSON.stringify(data),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        );
        
        
        if(data.name6 && data.name6.trim() !== "undefined undefined" && data.name6.trim() !== ""){
          window.location.href = "https://pmny.in/Ur5jM55xZeAt"
        }
        else if(data.name5 && data.name5.trim() !== "undefined undefined" && data.name5.trim() !== ""){
          window.location.href = "https://pmny.in/5JU9LCzht2gf"
        }
        else if (data.name4 && data.name4.trim() !== "undefined undefined" && data.name4.trim() !== "") {
          window.location.href = "https://pmny.in/zJ99cCnhJ3Lu"
        }
        
        console.log("success")
        console.log(response.data);
        
        // console.log(data);
        // console.log(JSON.stringify(response));
        setSuccess(true);
    } catch (err) {
      if (!err?.response) {
          setErr('No Server Response');
          console.log('No Server Response');
          console.log(err)
      } else {
          setErr('Registration Failed')
          console.log('Registration Failed')
      }
  }
  return data;
}


const updatebackenddata = async (pData) => {
  for (const [index, player] of pData.entries()) {
    console.log(index);
      switch (index) {
          case 0:
              await setPlayer1Data(player);
              break;
          case 1:
              await setPlayer2Data(player);
              break;
          case 2:
              await setPlayer3Data(player);
              break;
          case 3:
              await setPlayer4Data(player);
              break;
          case 4:
              await setPlayer5Data(player);
              break;
          case 5:
              await setPlayer6Data(player);
              break;
          default:
              break;
      }
  }
  return data; // Ensure that data is defined elsewhere in your code
};

    
    const data = {
      name: applicantname,
      email: applicantemail,
      contact: applicantcontact,
      college: applicantcollege,
  
      teamname: teamname,
      teamleader: teamleader,
      game: selectedEvent,
  
      name1: player1data?.Name,
      email1: player1data?.email,
      contact1: player1data?.contactno,
      ign1: player1data?.gameID,
      rollno1:player1data?.rollno,
      pfp1: player1data?.pfp,
      IdCard1: player1data?.collegeID,
  
      name2: player2data?.Name,
      email2: player2data?.email,
      contact2: player2data?.contactno,
      ign2: player2data?.gameID,
      rollno2:player2data?.rollno,
      pfp2: player2data?.pfp,
      IdCard2: player2data?.collegeID,
  
      name3: player3data?.Name,
      email3: player3data?.email,
      contact3: player3data?.contactno,
      ign3: player3data?.gameID,
      rollno3:player3data?.rollno,
      pfp3: player3data?.pfp,
      IdCard3: player3data?.collegeID,
  
      name4: player4data?.Name,
      email4: player4data?.email,
      contact4: player4data?.contactno,
      ign4: player4data?.gameID,
      rollno4:player4data?.rollno,
      pfp4: player4data?.pfp,
      IdCard4: player4data?.collegeID,
  
      name5: player5data?.Name,
      email5: player5data?.email,
      contact5: player5data?.contactno,
      ign5: player5data?.gameID,
      rollno5:player5data?.rollno,
      pfp5: player5data?.pfp,
      IdCard5: player5data?.collegeID,

      name6: player6data?.Name,
      email6: player6data?.email,
      contact6: player6data?.contactno,
      ign6: player6data?.gameID,
      rollno6:player6data?.rollno,
      pfp6: player6data?.pfp,
      IdCard6: player6data?.collegeID,
  
      
  };
  
  


    


  const handleNameChange = (event) => {
    setApplicantname(event.target.value)
  }
  
  const handleApplicantEmailChange = (event) => {
    setApplicantemail(event.target.value)
  }
  const handleApplicantContactChange = (event) => {
    setApplicantcontact(event.target.value)
  }
  
  const handleApplicantCollegeChange = (event) => {
    const selectedCollege = event.target.value;
    if (selectedCollege === "Other") {
      setShowOtherCollegeInput(true);
    } else {
      setShowOtherCollegeInput(false);
      setApplicantCollege(selectedCollege);
    }
  };
  const handleteamnamechange = (event) => {
    setTeamName(event.target.value)
  }
  const handleteamleaderchange = (event) => {
    setTeamleader(event.target.value)
  }
  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
    
  };

  useEffect(()=>{
    console.log(selectedEvent);
  },[selectedEvent])
  
  const handleNext = () => {
    const screenToPlayerNumber = {
      3: 1,
      4: 2,
      5: 3,
      6: 4,
      7: 5,
      8: 6
    };

    const playerNumber = screenToPlayerNumber[currentScreen+1];
    if (playerNumber !== undefined) {
        setPlayerNumber(playerNumber);
    }
    setCurrentScreen(currentScreen + 1);
    console.log(currentScreen+1);
  };

  const handleBack = () => {
    const screenToPlayerNumber = {
      3: 1,
      4: 2,
      5: 3,
      6: 4,
      7: 5,
      8: 6
    };

    const playerNumber = screenToPlayerNumber[currentScreen-1];
    if (playerNumber !== undefined) {
        setPlayerNumber(playerNumber);
    }
    
    
    // console.log(`Screen Going Back ${currentScreen-1}`)
    setCurrentScreen(currentScreen -1);
  };
  

  const handlesum = ()=>{
    setSum(true);
  }

  const renderScreen = (screenNumber) => {
    switch (screenNumber) {
      case 1:
        return (
          <div>
            <div>
              <form className="registration-form" onSubmit={handleNext}>
                <h3 className="registration-regform gamingevent">Gaming Event</h3>
                <hr />
                <h1 className="registration-regform">Registration Form</h1>
                <div className="main-container">
                  <div className="registration-inner-regform" >
                    <input className="registration-input" onChange={handleNameChange} name="fname" type="text" placeholder="Full Name" required />
                    <input className="registration-input" onChange={handleApplicantEmailChange} name="email" type="email" placeholder="E-mail" required />
                    <input className="registration-input" onChange={handleApplicantContactChange} name="tel" type="tel" placeholder="Phone No." required />
                    
                  </div>
                  <div className="registration-course">
                      <select name="registration-course" id="course" onChange={handleApplicantCollegeChange} required>
                        <option value="" disabled selected>Select College</option>
                        <option className="registration-course-option" value="CGC Jhanjeri">CGC Jhanjeri</option>
                        <option className="registration-course-option" value="CGC Landran">CGC Landran</option>
                        <option className="registration-course-option" value="Chandigarh University">CU</option>
                        <option className="registration-course-option" value="Chitkara">Chitkara</option>
                        <option className="registration-course-option" value="Lovely Professional University">LPU</option>
                        <option className="registration-course-option" value="DAV">DAV</option>
                        <option className="registration-course-option" value="PU">PU</option>
                        <option className="registration-course-option" value="PEC">PEC</option>
                        <option className="registration-course-option" value="THAPAR">THAPAR</option>
                        <option className="registration-course-option" value="AMITY">AMITY</option>
                        <option className="registration-course-option" value="RAYAT-BAHARA">RAYAT BAHARA</option>
                        <option className="registration-course-option" value="Other">Other</option>
                      </select>
                      {showOtherCollegeInput && ( // Render input field if "Other" is selected
                    <input className="registration-input" onChange={(e) => setApplicantCollege(e.target.value)} value={applicantcollege} type="text" placeholder="Enter College Name" required />
                  )}
                  </div>
                </div>
                <hr />
                <div className="registration-radio-flexbtn">
                  <button className="registration-submit-btn" type="submit">Next</button>
                </div>
              </form>
              
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <form className="registration-screen-2-team-form" onSubmit={handleNext} >
              <h1 className="registration-team-head registration-event-head"> Select Event</h1>
              <div>
                <input className="registration-input registration-teamname" onChange={handleteamnamechange} name="team_name" type="text" placeholder="Team Name" required/>
                <br />
                <input className="registration-input registration-teamname" onChange={handleteamleaderchange} type="text" placeholder="Team Leader Name" required/>
                <div className="registration-radio-logo">
                </div>
                <div className="registration-event-radio">
                  <div className="registration-event-sub-radio-1">
                    <div className="registration-event-select">
                        <label htmlFor="event-Toykathon" className='events-ace-sub-heading'><img className='registration-logo' src={toycathonlogo} alt="Error in loading Toykathon Image" />24Hr Game Dev Event</label>
                        <input className='registration-event-selec-radio-btn' type="radio" id="event-Toykathon" name="event" value="Toykathon" onChange={handleEventChange} required />
                    </div>
                  </div>
                </div>

              </div>
              <div className="registration-radio-flexbtn">
                <input className="registration-radio-submit-btn " onClick={handleBack} type="button" value="Back" />
                <input className="registration-radio-submit-btn " type="submit" value="Next" />
              </div>
            </form>
          </div>
        );
      case 3:
        if (selectedEvent === "Ace") {
          return (
          <PlayerInfo playerNumber={1} event={selectedEvent} key={playerNumber} playerdata = {player1data} handleBack={handleBack} handleNext={handleNext} saveData={saveData} updatebackenddata={updatebackenddata} err={err}/>
          );
        } else if (selectedEvent === "HotDrop" || selectedEvent === "Toykathon" || selectedEvent === "Booyah") {
          return <PlayerInfo playerNumber={1} event={selectedEvent} key={playerNumber} playerdata = {player1data} handleBack={handleBack} handleNext={handleNext} saveData={saveData} updatebackenddata={updatebackenddata}  err={err}/>;
        }
        
        else{
          setCurrentScreen(2)
        }
        break;
      case 4:
        if (selectedEvent === "Ace") {
          return <PlayerInfo playerNumber={2} event={selectedEvent} key={playerNumber} playerdata = {player2data} handleBack={handleBack} handleNext={handleNext} saveData={saveData} updatebackenddata={updatebackenddata} err={err}/>;
        } else if (selectedEvent === "HotDrop" || selectedEvent === "Toykathon" || selectedEvent === "Booyah") {
          return <PlayerInfo playerNumber={2} event={selectedEvent} key={playerNumber} playerdata = {player2data} handleBack={handleBack} handleNext={handleNext} saveData={saveData} updatebackenddata={updatebackenddata} err={err}/>;
        }
        break;
      case 5:
        if (selectedEvent === "Ace") {
          return <PlayerInfo playerNumber={3} event={selectedEvent} key={playerNumber} playerdata = {player3data} handleBack={handleBack} handleNext={handleNext} saveData={saveData} updatebackenddata={updatebackenddata} err={err}/>;
        } else if (selectedEvent === "HotDrop" || selectedEvent === "Toykathon" || selectedEvent === "Booyah") {
          return <PlayerInfo playerNumber={3} event={selectedEvent} key={playerNumber} playerdata = {player3data} handleBack={handleBack} handleNext={handleNext} saveData={saveData} updatebackenddata={updatebackenddata} err={err}/>;
        }
        break;
      case 6:
        if (selectedEvent === "Ace") {
          return <PlayerInfo playerNumber={4} event={selectedEvent} key={playerNumber} playerdata = {player4data} handleBack={handleBack} handleNext={handleNext} saveData={saveData} updatebackenddata={updatebackenddata} err={err}/>;
        } else if (selectedEvent === "HotDrop" || selectedEvent === "Toykathon" || selectedEvent === "Booyah") {
          return <PlayerInfo playerNumber={4} event={selectedEvent} key={playerNumber} playerdata = {player4data} handleBack={handleBack} handleNext={handleNext} saveData={saveData} updatebackenddata={updatebackenddata} err={err}/>;
        }
        break;
      case 7:
        if (selectedEvent === "Ace") {
          return <PlayerInfo playerNumber={5} event={selectedEvent} key={playerNumber} playerdata = {player5data} handleBack={handleBack} handleNext={handleNext} saveData={saveData} updatebackenddata={updatebackenddata} err={err}/>;
        } else if (selectedEvent === "HotDrop" || selectedEvent === "Toykathon" || selectedEvent === "Booyah") {
          return <PlayerInfo playerNumber={5} event={selectedEvent} key={playerNumber} playerdata = {player5data} handleBack={handleBack} handleSum={handlesum} saveData={saveData} updatebackenddata={updatebackenddata}  err={err}/>;
        }
        break;
      case 8:
        if (selectedEvent === "Ace") {
          return <PlayerInfo playerNumber={6} event={selectedEvent} key={playerNumber} playerdata = {player6data} handleBack={handleBack} handleSum={handlesum}  saveData={saveData} updatebackenddata={updatebackenddata} success={success} err={err}/>;
        }
        break;
      
      default:
      return null;
    }
  };
  




return (
  <>
    <div className="registration-main">
      {!sum ? (
        <div className={`registration-section ${currentScreen === "rules" ? "expand" : ""}`}>
          {renderScreen(currentScreen)}
          {currentScreen === 3 && selectedEvent === "Ace" && (
            <div className="registration-rules">
              <h4>RULES AND INSTRUCTIONS</h4>
              <ol>
                <li>Each team must consist of 5 core members, with an optional 6th player for added flexibility.</li>
                <li>While you're welcome to bring your own peripherals to the venues, please note that they will be at your own responsibility.</li>
                <li>Please ensure that all information provided in this form is accurate and legitimate. Any inaccuracies discovered will result in the cancellation of your application.</li>
                <li>It is mandatory that all participants within a team belong to the same college or university to maintain fairness and integrity.</li>
                <li>Participants are encouraged to foster teamwork and camaraderie throughout the event to enhance the overall experience.</li>
                <li>Ensure a memorable experience by participating actively and engaging with fellow participants and organizers.</li>
                <li>Your feedback and suggestions are highly valued as we strive to improve future events and provide the best experience for all participants.</li>
              </ol>
            </div>
          )}
          {currentScreen === 3 && (selectedEvent === "HotDrop" || selectedEvent === "Booyah") && (
            <div className="registration-rules">
              <h4>RULES AND INSTRUCTIONS</h4>
              <ol>
                <li>Each team must consist of 4 core members, with an optional 5th player for added flexibility.</li>
                <li>All participants must ensure they come equipped with their own mobile devices for the event.</li>
                <li>While you're welcome to bring your own peripherals to the venues, please note that they will be at your own responsibility.</li>
                <li>Please ensure that all information provided in this form is accurate and legitimate. Any inaccuracies discovered will result in the cancellation of your application.</li>
                <li>It is mandatory that all participants within a team belong to the same college or university to maintain fairness and integrity.</li>
                <li>Participants are encouraged to foster teamwork and camaraderie throughout the event to enhance the overall experience.</li>
                <li>Ensure a memorable experience by participating actively and engaging with fellow participants and organizers.</li>
                <li>Your feedback and suggestions are highly valued as we strive to improve future events and provide the best experience for all participants.</li>
              </ol>
            </div>
          )}
          {currentScreen === 3 && (selectedEvent === "Toykathon") && (
            <div className="registration-rules">
              <h4>RULES AND INSTRUCTIONS</h4>
              <ol>
                <li>All participants must ensure they come equipped with their own mobile devices for the event.</li>
                <li>While you're welcome to bring your own peripherals to the venues, please note that they will be at your own responsibility.</li>
                <li>Please ensure that all information provided in this form is accurate and legitimate. Any inaccuracies discovered will result in the cancellation of your application.</li>
                <li>It is mandatory that all participants within a team belong to the same college or university to maintain fairness and integrity.</li>
                <li>Participants are encouraged to foster teamwork and camaraderie throughout the event to enhance the overall experience.</li>
                <li>Ensure a memorable experience by participating actively and engaging with fellow participants and organizers.</li>
                <li>Your feedback and suggestions are highly valued as we strive to improve future events and provide the best experience for all participants.</li>
              </ol>
            </div>
          )}
        </div>
      ) : (
        <>
          {success ? (
            <div className="signup-success-main">
              <div className="signup-success-main-core">
                <div className="signup-success-switch__container" id="switch-c2">
                  <h2 className="signup-success-switch__title signup-success-title">Application Submitted!</h2>
                  <p className="signup-success-switch__description signup-success-description">An acknowledgment of your application has been dispatched to your email inbox, along with a unique Application ID for your reference.</p>
                  <p className="signup-success-switch__description signup-success-description">Additionally, we recommend checking your Spam folder for the email to ensure you don't miss any important correspondence.</p>
                  <button className="signup-success-switch__button signup-success-button signup-success-switch-btn" onClick={() => { navigate("/home"); }}>HOME</button>
                </div>
              </div>
            </div>
          ) : (
            <Summary data={data} handleSubmit={handleSubmit} />
          )}
        </>
      )}
    </div>
  </>


  );
  
};

export default Registration;
