import React, { useState, useEffect } from 'react';
import { Bar,Pie,Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement,ArcElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import axios from '../../api/axios';

ChartJS.register(ArcElement,BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const Team_Data = async () => {
    try {
        const response = await axios.get('/registration/team');

        const getCollegeCounts = (data) => {
            const collegeCounts = {};
            data.forEach(team => {
                const collegeName = team.college;
                if (collegeCounts[collegeName]) {
                    collegeCounts[collegeName]++;
                } else {
                    collegeCounts[collegeName] = 1;
                }
            });
            return collegeCounts;
        };

        const getEventCounts = (data) => {
            const eventCounts = {};
            data.forEach(team => {
                const eventName = team.event;
                if (eventCounts[eventName]) {
                    eventCounts[eventName]++;
                } else {
                    eventCounts[eventName] = 1;
                }
            });
            return eventCounts;
        };

        const collegeCounts = getCollegeCounts(response.data);
        const eventCounts = getEventCounts(response.data);
        return {collegeCounts,eventCounts};
    } catch (err) {
        console.error(err);
    }
}

export const BarChartComponent = () => {
    const [collegeData, setCollegeData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const data = await Team_Data();
            setCollegeData(data.eventCounts);
            
        };
        fetchData();
    }, []);

    if (!collegeData) {
        return <div>Loading...</div>;
    }

    const data = {
        labels: Object.keys(collegeData),
        datasets: [
            {
                label: 'No. of Teams',
                data: Object.values(collegeData),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 2,
                barThickness: 80,
                
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        
        plugins: {
            legend: {
                position: 'top',
                display: false,
                labels: {
                    color: 'White', // Custom legend text color
                    font: {
                        size: 14, // Custom legend font size
                        weight: 'bold' // Custom legend font weight
                    }
                }
            },
            title: {
                display: false,
                text: 'No. of Teams per College',
                color: 'White', // Custom title color
                font: {
                    size: 18, // Custom title font size
                    weight: 'bold', // Custom title font weight
                },
                align: 'center', // Title alignment
            },
            tooltip: {
                enabled: true,
                backgroundColor: 'rgba(0,0,0,0.8)', // Custom tooltip background color
                titleColor: 'white', // Custom tooltip title color
                bodyColor: 'white', // Custom tooltip body color
                borderColor: 'white', // Custom tooltip border color
                borderWidth: 1, // Custom tooltip border width
            }
        },
        scales: {
            x: {
                ticks: {
                    display:false,
                    color: 'white', // Custom x-axis text color
                    font: {
                        size: 12,
                         // Custom x-axis font size
                    }
                },
                grid: {
                    display: false, // Hide x-axis grid lines
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    color: 'white', // Custom y-axis text color
                    font: {
                        size: 12, // Custom y-axis font size
                    }
                },
                grid: {
                    color: 'rgba(200, 200, 200, 0.2)', // Custom y-axis grid color
                }
            }
        }
    };

    return <Bar data={data} options={options} />;
};

export const BarChartComponent2 = () => {
    const [collegeData, setCollegeData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const data = await Team_Data();
            setCollegeData(data.collegeCounts);
            
        };
        fetchData();
    }, []);

    if (!collegeData) {
        return <div>Loading...</div>;
    }

    const data = {
        labels: Object.keys(collegeData),
        datasets: [
            {
                label: 'No. of Teams',
                data: Object.values(collegeData),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 2,
                barThickness: 75,
                
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        
        plugins: {
            legend: {
                position: 'top',
                display: false,
                labels: {
                    color: 'White', // Custom legend text color
                    font: {
                        size: 14, // Custom legend font size
                        weight: 'bold' // Custom legend font weight
                    }
                }
            },
            title: {
                display: false,
                text: 'No. of Teams per College',
                color: 'White', // Custom title color
                font: {
                    size: 18, // Custom title font size
                    weight: 'bold', // Custom title font weight
                },
                align: 'center', // Title alignment
            },
            tooltip: {
                enabled: true,
                backgroundColor: 'rgba(0,0,0,0.8)', // Custom tooltip background color
                titleColor: 'white', // Custom tooltip title color
                bodyColor: 'white', // Custom tooltip body color
                borderColor: 'white', // Custom tooltip border color
                borderWidth: 1, // Custom tooltip border width
            }
        },
        scales: {
            x: {
                ticks: {
                    display:false,
                    color: 'white', // Custom x-axis text color
                    font: {
                        size: 12,
                         // Custom x-axis font size
                    }
                },
                grid: {
                    display: false, // Hide x-axis grid lines
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    color: 'white', // Custom y-axis text color
                    font: {
                        size: 12, // Custom y-axis font size
                    }
                },
                grid: {
                    color: 'rgba(200, 200, 200, 0.2)', // Custom y-axis grid color
                }
            }
        }
    };

    return <Bar data={data} options={options} />;
};


export const DonutChartComponent = () => {
    const [collegeData, setCollegeData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const data = await Team_Data();
            setCollegeData(data.collegeCounts);
        };
        fetchData();
    }, []);

    if (!collegeData) {
        return <div>Loading...</div>;
    }

    const data = {
        labels: Object.keys(collegeData),
        datasets: [
            {
                label: 'No. of Teams',
                data: Object.values(collegeData),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 2,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                display: false,
                labels: {
                    color: 'blue', // Custom legend text color
                    font: {
                        size: 14, // Custom legend font size
                        weight: 'bold' // Custom legend font weight
                    }
                }
            },
            title: {
                display: false,
                text: 'No. of Teams per College',
                color: 'darkblue', // Custom title color
                font: {
                    size: 18, // Custom title font size
                    weight: 'bold', // Custom title font weight
                },
                align: 'center', // Title alignment
            },
            tooltip: {
                enabled: true,
                backgroundColor: 'rgba(0,0,0,0.8)', // Custom tooltip background color
                titleColor: 'white', // Custom tooltip title color
                bodyColor: 'white', // Custom tooltip body color
                borderColor: 'white', // Custom tooltip border color
                borderWidth: 1, // Custom tooltip border width
            }
        }
    };

    return <Doughnut data={data} options={options} />;
};