import React from 'react';
import './Hotdrop.css';
import hotdropimg from '../../assets/Core/HotDrop Logo - Transparent.png'
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from 'react-router-dom';
import Countdown from 'react-countdown';
import { Link } from 'react-router-dom';

const targetDate = new Date('2024-03-22T12:00:00').getTime();

const Home = () => {
  const navigate = useNavigate();
  
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <div className="home-button-container">
      {/* <button className="home-cssbuttons-io-button" onClick={() => { navigate("/registration"); }}>REGISTER NOW!
        <div className="home-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path></svg>
        </div>
      </button> */}
      <h1>Slots Full Registrations Closed</h1>
    </div>;
    } else {
      // Render countdown elements separately
      return (
        <div className="ace-countdown">
          {days !== 0 && (
            <>
              <div className='ace-count-heading'>{days.toString().padStart(2, '0')}</div>


            :
            </>
          )}
            <>
              <div className='ace-count-heading'>{hours.toString().padStart(2, '0')}</div>


            :
            </>
            <>
              <div className='ace-count-heading'>{minutes.toString().padStart(2, '0')}</div>


            :
            </>
            <>
              <div className='ace-count-heading'>{seconds.toString().padStart(2, '0')}</div>


            
            </>
          
            

        </div>
      );
    }
  };
  


  return (
    <div className='hotdrop-main'>
      <div className='hotdrop-cards-row'>
        <div className='hotdrop-horizontal-card'>
          <div className='hotdrop-horizontal-card-left'>
            <img className="hotdrop-horizontal-card-userimage" alt="" src={hotdropimg}/>
          </div>
          <div className='hotdrop-horizontal-card-right'>
            {/* <p>Click here to Register</p> */}
            <p className='register_open_date_time'>An E-Sports Level BGMI Tournament</p>
            <Countdown date={targetDate} renderer={renderer} />

            {/* <Link to='/Registration'><button className="hotdrop-event-button" type='button'>Register</button></Link> */}
          </div>
        </div>
      </div>
      <div className="hotdrop-bottom">
        <div className='hotdrop-info'>
          <p>BGMI is a Indian version of game PUBG mobile which is exclusively available in India. We here at CGC are offering you a platform "HOTDROP" to show your gaming skills. Embark on your BGMI gaming journey in our point based tournament. Aiming for your direct victory in our thrilling competition.

Teams will accumulate points on every kill, each victory, and their final rank in every match. These metrics will determine their standings on the ranking table.

Inspired by the international stages, our tournament format aims to replicate the thrilling experience for all the participants. Be a Game changer, not just a gamer!!!</p>
        </div>
        


      </div>

    </div >


  )
}

export default Home












