import React from 'react';
import './Schedule.css';
import scheduleData from './scheduleData';


const About = () => {
  return (
    <div className="sch-main-div">
      <div class="sch-container">
        <div class="timeline">
          <div class="timeline-month">
            March 2024 
          </div>
          <div className="timeline-card-container">
            {scheduleData.map((data)=>(
                <div class="timeline-section">
                  <div class="timeline-date">
                    {data.date}
                  </div>
                  {data.events.map((event)=>(
                    <div class="timeline-box">
                      <div class="box-title">
                        <i class="fa fa-asterisk text-success" aria-hidden="true"></i> {event.title}
                      </div>
                      <div class="box-content">
                        <p>{event.details.desc}</p>
                        {event.details.desc &&(
                        <p><strong>{event.details.addInfo}</strong></p>
                        )}
                        {!event.details.desc &&(
                          <p><strong>Will be Updated soon ~Team Mirage</strong></p>
                        )}
                        
                      </div>
                      <div class="box-footer">{event.footer}</div>
                    </div>
                  ))}
                </div>
              ))
            }
          </div>

          
          
          
          

        </div>
      </div>
    </div>
  )
}

export default About







      
    
    