// membersData.js
import karan from  '../../assets/Team images/Karan.jpg'
import pragyansh from "../../assets/Team images/paragyansh.jpg"
import navneet from "../../assets/Team images/navneet.jpg"
import mohit from "../../assets/Team images/mohit.jpg"
import shekhar from "../../assets/Team images/shekhar.jpg"
import abhinav from "../../assets/Team images/Abhinav.jpg"

import { faHtml5, faCss3Alt, faJs,faReact,faPython,faAws,faNodeJs, faLinkedin, faGithub} from '@fortawesome/free-brands-svg-icons'; 
import { faInstagram,faFacebook,faDiscord,faTwitter,faReddit,faAndroid } from '@fortawesome/free-brands-svg-icons'; 
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export const skillsData = [
    { name: "HTML", icon: faHtml5 },
    { name: "CSS", icon: faCss3Alt },
    { name: "JavaScript", icon: faJs },
    { name: "React", icon: faReact },
    { name: "Python", icon: faPython },
    { name: "Aws", icon: faAws },
    { name: "NodeJs", icon: faNodeJs },
    { name:"Android", icon:faAndroid },
    
    
];
export const socialMediaData = {
    "facebook": faFacebook,
    "twitter": faTwitter,
    "instagram": faInstagram,
    "discord":faDiscord,
    "reddit": faReddit,
    "linkedin":faLinkedin,
    "github":faGithub,
    "email":faEnvelope

};



const membersData = [
    {
        name: "Karan Kumar Agrawal",
        role: "Lead Developer",
        description: `A versatile individual with a passion for coding, gaming, and entrepreneurship. Whether I'm crafting lines of code, exploring virtual worlds, or chasing innovative ideas, I thrive on creative challenges and strategic thinking. Tech, health, and fitness are integral to my lifestyle.`,
        imageUrl: karan,
        socialMedia: {
            instagram: "https://www.instagram.com/official.kxran/",
            linkedin:"https://www.linkedin.com/in/karan-agrawal-977746232/",
            twitter:"https://twitter.com/karann__25",
            github:"https://github.com/KDrop25",
            email:"mailto:karan25.pvt@gmail.com"
        },
        skills: ["JavaScript","HTML","NodeJs","Aws","Python","React","CSS"],
        
        
    },
    
    {
        name: "Prayansh Chauhan",
        role: "Frontend Developer",
        description: "Hailing from Kota, Rajasthan, I'm passionate about technology, actively refining my frontend web development skills to blend creativity with functionality. With a keen eye for detail and a commitment to excellence, I aim to leverage my education and experience to contribute meaningfully in the ever-evolving tech landscape.",
        imageUrl:pragyansh,
        socialMedia: {
            instagram: "https://www.instagram.com/xpragyansh/",
            github: "https://www.facebook.com/",
            twitter:"https://twitter.com/PragyanshC2905",
            linkedin:"https://www.linkedin.com/in/pragyansh-chauhan-488835295/",
            facebook:"https://www.facebook.com/pragyansh.chauhan",
            email:"mailto:cpragyansh@gmail.com"

        },
        skills: ["HTML", "CSS", "JavaScript" , "React"]
    },
    
    {
        name: "Navneet Singh",
        role: "Frontend Developer",
        description: `Belonging from Sirsa, haryana, pursuing Btech in the stream of artificial intelligence and machine learning at Chandigarh Group of Colleges Jhanjeri. 
        My hobbies are exploring new things and drawing.
        Spending hours on coding and improving my skills on daily basis. 	
          Prioritising  coding skills shows my  passion for coding.
        `,
        imageUrl:navneet,
        socialMedia: {
            instagram: "https://www.instagram.com/navneetx07",
            github: "https://www.github.com/Navneetsinghx07",
            twitter:"https://twitter.com/navneetx07",
            linkedin:"https://www.linkedin.com/in/navneetsingh07/",
            email:"mailto:navneetx07@gmail.com"
        },
        skills: ["HTML", "CSS"]
    },
    {
        name: "Mohit Choudhary",
        role: "Frontend Developer",
        description: `Hailing from Himachal Pradesh, playing video games and travelling makes me thrilled.
        My goal in life is to get a job at Google.
        Love for  programming languages, that's what makes me passionate about coding.
        `,
        imageUrl:mohit,
        socialMedia: {
            instagram: "https://www.instagram.com/mohit.choudharry",
            twitter:"https://twitter.com/Mohit__990",
            linkedin:"https://www.linkedin.com/in/mohit-choudhary-0026122b5?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
            email:"mailto:navneetx07@gmail.com"
        },
        skills: ["HTML", "CSS"]
    },
    {
        name: "Shekhar Kashyap",
        role: "Backend Developer",
        description: `Currently I am pursuing B.Tech. in CSE from Chandigarh Engineering College, Jhanjeri Mohali. Playing cricket and online games is what I love to do.
        A full stack Web developer with extra skills of C,C++ and OOPs ,and willing to grow efficiently in this field`,
        imageUrl:shekhar,
        socialMedia: {
            instagram: "https://www.instagram.com/Shekhar0165",
            github:"https://github.com/Shekhar0165",
            twitter:"https://x.com/Shekhar00165?t=he32UfirUfITe1Qx7WiJ1Q&s=09",
            linkedin:"https://www.linkedin.com/in/shekhar0165",
            email:"mailto:shekharkashyap913@gmail.com"

        },
        skills: ["HTML", "CSS", "JavaScript","React","NodeJs"]
    },
    
    {
        name: "Abhinav Aggarwal",
        role: "Graphic Designer",
        description: `Hey, I am Abbinav from Sirsa, Haryana. Working with the team as a graphic designer lead. I am expert in canva.`,
        imageUrl: abhinav,
        socialMedia: {
            instagram: "https://www.instagram.com/abhinavaggarwal02/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D",
            email:"mailto:abhinavaggarwal0104@gmail.com"
        },
        skills: [],
        
        
    },
    
    
    

];

export default membersData;
